<template>
  <h2 class="text-primary-200 blue-gradient-dark p-small text-sm sm:text-lg">
   electronical Health Professional Licensing (eHPL)  © Ministry of Health - Ethiopia {{ new Date().getFullYear() }}
  </h2>
</template>
<style lang="postcss" scoped>
h2 {
  background-image: linear-gradient(to right, #07677e, #03333F);
}
</style>
